import React, { useState, useRef, useEffect } from 'react';
import { AddPhotoApiArg, useAddPhotoMutation } from '../../store/apis/ipr-api';
import { Spinner } from '../../common/components/Spinner';
import toast from 'react-hot-toast';

function fileToBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result && typeof reader.result == 'string' ? reader.result.substr(23) : null);
    reader.onerror = (error) => reject(error);
  });
}

interface Coordinates {
  longitude: number;
  latitude: number;
}

const VesselIdentification = () => {
  const [addPhoto, { isLoading, data }] = useAddPhotoMutation();
  const [image, setImage] = useState<Blob | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [verticalHeight, setVerticalHeight] = useState<string>('0vh');
  const [boxarray, setBoxarray] = useState<number[][]>([]);
  const [coordinates, setCoordinates] = useState({ longitude: 0, latitude: 0 });
  let canvasRef = useRef<HTMLCanvasElement>(null);
  let imgRef = useRef<HTMLImageElement>(null);

  const colors = [
    '#87CEFA',
    '#F08080',
    '#B0C4DE',
    '#778899',
    '#FFDAB9',
    '#98FB98',
    '#AFEEEE',
    '#E0FFFF',
    '#FFA07A',
    '#F5FFFA',
  ];

  const defaultColor = '#D3D3D3';

  const getCurrentTimestamp = (): string => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const timezoneOffset = -now.getTimezoneOffset();
    const timezoneHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const timezoneMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
    const timezoneSign = timezoneOffset >= 0 ? '+' : '-';
    const timezone = `${timezoneSign}${timezoneHours}${timezoneMinutes}`;

    const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezone}`;

    return timestamp;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target?.files.length > 0) {
      setVerticalHeight('0vh');
      setBoxarray([]);
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await getLocation();
    } catch (error) {}
    const currentVertHeight = await getVerticalHeight();
    setVerticalHeight(currentVertHeight);
    if (image) {
      try {
        const convertedImage = await fileToBase64(image);

        const body: AddPhotoApiArg = {
          photo: {
            file: { name: 'test.jpg', content: convertedImage },
            location: { latitude: coordinates.latitude, longitude: coordinates.longitude },
            timestamp: getCurrentTimestamp(),
          },
        };
        const x = await addPhoto(body).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getVerticalHeight = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = imgRef.current;
      if (img?.naturalHeight! > img?.naturalWidth!) {
        resolve('80vh');
      } else {
        resolve('30vh');
      }
    });
  };

  const getLocation = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude;
            const latitude = position.coords.latitude;
            const coordinates: Coordinates = {
              longitude: longitude,
              latitude: latitude,
            };
            setCoordinates(coordinates);
            resolve();
          },
          (error) => {
            reject();
          }
        );
      } else {
        reject();
      }
    });
  };

  useEffect(() => {
    if (data?.detections && data.detections.length > 0) {
      setBoxarray(data.detections.map((detection) => detection.bbox || []));
    }
  }, [data]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const img = imgRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);

        boxarray.forEach((box, index) => {
          let [x, y, width, height] = box;
          width = width - x;
          height = height - y;

          const scaledX = (x / img?.naturalWidth!) * canvas.width;
          const scaledY = (y / img?.naturalHeight!) * canvas.height;
          const scaledWidth = (width / img?.naturalWidth!) * canvas.width;
          const scaledHeight = (height / img?.naturalHeight!) * canvas.height;

          context.beginPath();
          context.rect(scaledX, scaledY, scaledWidth, scaledHeight);
          context.strokeStyle = colors[index] || defaultColor;
          context.font = '30px Arial';
          context.lineWidth = 2;
          context.stroke();
        });
      }
    }
  }, [boxarray, imgRef.current, canvasRef.current, imgRef.current?.src]);

  return (
    <div className="flex flex-col items-center mt-20 text-primary-content">
      <div className="my-4">
        IPR still in training. Make sure your picture includes location data or allow browser location for instant
        recognition!
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <label htmlFor="image">Upload an image:</label>
        <input type="file" id="image" onChange={handleImageChange} />
        {imageUrl && (
          <div style={{ position: 'relative' }} className="my-4">
            <img
              src={imageUrl}
              alt="vessel"
              style={{ height: verticalHeight, width: 'auto', border: '1px solid gray' }}
              ref={imgRef}
            />
            <canvas
              ref={canvasRef}
              style={{
                height: verticalHeight,
                width: '100%',
                border: '1px solid gray',
                position: 'absolute',
                top: '0',
                left: '0',
              }}
            />
          </div>
        )}

        {isLoading ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="btn btn-sm bg-[#08435f] hover:bg-[#08435f]/70 border-[#08435f] mt-2 w-full text-primary-content"
          >
            Submit
          </button>
        )}
      </form>
      <div className="my-4">
        <a
          className="link-hover"
          target="_blank"
          href="https://www.yammer.com/wartsila.com/#/threads/inGroup?type=in_group&feedId=7853670400"
        >
          Yammer
        </a>
        {' | '}
        <a className="link-hover" target="_blank" href="https://sway.office.com/1eLm4aDZrGR7CUX3">
          Instructions
        </a>
      </div>
      <div>
        <h2>Possible matches: </h2>
        {data?.detections?.map((vessel, index) => (
          <div className="ds-mb-base">
            <a
              className="link font-medium no-underline"
              href={`/vessel/${vessel.imo}`}
              style={{ color: colors[index] || defaultColor }}
              onMouseEnter={(e) => (e.currentTarget.style.color = '#FFFFFF')}
              onMouseLeave={(e) => (e.currentTarget.style.color = colors[index] || defaultColor)}
            >
              {vessel.vessel_name} {vessel.imo && `(IMO: ${vessel.imo})`}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VesselIdentification;
